import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { setUser } from "../services/auth";
import { navigate } from "gatsby";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@mui/styles";
import { isLoggedIn, gtmPushUserInfo } from "../services/auth";

import {
  Button,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";

const LoginBlock = ({ prevPath }) => {
  const theme = useTheme();
  const { t, language } = useI18next();
  const [showAccountNotConfirmedMessage, setShowAccountNotConfirmedMessage] =
    useState(false);

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_connect: isLoggedIn() ? "login" : "logout",
      page_category: "Login",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const pushDatalayerAfterLogin = (data) => {
    window.dataLayer?.push({
      event: "page_info",
      page_connect: "login",
    });
  };

  const ADMIN_SIGNIN = gql`
    mutation ($identifier: String!, $password: String!) {
      login(input: { identifier: $identifier, password: $password }) {
        jwt
        user {
          id
          userLastName
          userFirstName
          profession
          country
          email
          areasOfExpertise
          progress
          quizzResults
          subscribedEvents
          subscribedNewsletter
        }
      }
    }
  `;
  const [verificationToken, { loading: loadingVerificationToken }] =
    useMutation(ADMIN_SIGNIN);

  const handleSubmit = (event) => {
    event.preventDefault();
    verificationToken({
      variables: {
        identifier: values.email,
        password: values.password,
      },
    })
      .then((data) => {
        setUser({
          id: data.data.login.user.id,
          email: values.email,
          token: data.data.login.jwt,
          userLastName: data.data.login.user.userLastName,
          userFirstName: data.data.login.user.userFirstName,
          country: data.data.login.user.country,
          profession: data.data.login.user.profession,
          areasOfExpertise: data.data.login.user.areasOfExpertise,
          progress: data.data.login.user.progress,
          quizzResults: data.data.login.user.quizzResults,
          subscribedEvents: data.data.login.user.subscribedEvents,
          subscribedNewsletter: data.data.login.user.subscribedNewsletter,
        });
        pushDatalayerAfterLogin(data);
        navigate(`/`);
        return;
      })
      .catch((error) => {
        // Handle error.
        console.log(
          "login : an error occurred:",
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message
        );
        if (
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message === "Identifier or password invalid."
        )
          setShowMessageInvalidEmailOrPassword(true);
        else if (
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message === "Your account email is not confirmed"
        )
          setShowAccountNotConfirmedMessage(true);
      });

    return false;
  };

  const [
    showMessageInvalidEmailOrPassword,
    setShowMessageInvalidEmailOrPassword,
  ] = useState(false);

  const [values, setValues] = React.useState({
    password: "",
    identifier: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClickRegister = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/register/`);
  };

  const onClickForgottenPassword = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/forgotten-password/`);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        py: 8,
        gap: 8,
      }}
    >
      <Link
        underline="hover"
        to={`/`}
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <ArrowBackIosNew />
        <Typography style={{ color: theme.palette.grey.servier_1 }}>
          {t("backHomePage")}
        </Typography>
      </Link>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          gap: 8,
        }}
      >
        {prevPath !== "/" && (
          <>
            <Typography sx={{ width: "35ch", textAlign: "center" }}>
              {t("login.noAccessMessage")}
            </Typography>
            <hr
              color={theme.palette.grey.servier_4}
              style={{
                width: "342px",
                height: "1px",
                margin: "0",
              }}
            ></hr>
          </>
        )}
        <Typography variant="h1">{t("login.login")}</Typography>
        <FormControl sx={{ width: "35ch" }} variant="standard">
          <InputLabel htmlFor="email">{t("login.email")}</InputLabel>
          <Input
            id="email"
            value={values.email}
            onChange={handleChange("email")}
          />
        </FormControl>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            style={{
              textTransform: "none",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={onClickForgottenPassword}
          >
            {t("login.forgottenPassword")}
          </Button>
          <FormControl sx={{ width: "35ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              {t("login.password")}
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        {showMessageInvalidEmailOrPassword && (
          <div>{t("login.invalidEmailOrPassword")}</div>
        )}
        {showAccountNotConfirmedMessage && (
          <div>{t("login.yourAccountIsNotConfirmed")}</div>
        )}
        <FormControl sx={{ width: "35ch" }} variant="standard">
          <Button
            disabled={!(values.password && values.email)}
            variant="contained"
            style={{
              letterSpacing: "0.1em",
              marginBottom: "1em",
            }}
            onClick={handleSubmit}
          >
            {t("login.login")}
          </Button>
          <Button
            style={{
              letterSpacing: "0.1em",
            }}
            onClick={onClickRegister}
          >
            {t("login.signUp")}
          </Button>
        </FormControl>
      </Container>
    </Container>
  );
};

export default LoginBlock;
