import React from "react";
import { graphql } from "gatsby";
import LoginBlock from "../components/login-block";
import { Box } from "@mui/material";
import SEO from "../components/seo";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@emotion/react";
import { getAlternateLang } from "../utils/alternate-lang";

export const query = graphql`
  query Login($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Login = (props) => {
  const theme = useTheme();
  const { language, t } = useI18next();

  const seo = {
    metaTitle: t("nav.login"),
    metaDescription: t("nav.login").toLocaleLowerCase(),
    linkAlternateHref: "/" + getAlternateLang(language) + "/login",
    linkHrefLang: getAlternateLang(language),
    noindex: true,
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.down("xl")]: {
          maxWidth: "100vw",
        },
      }}
    >
      <SEO seo={seo} />
      <LoginBlock prevPath={props.location.state?.prevPath} />
    </Box>
  );
};

export default Login;
